<template>
  <div class="deal-monitor">
    <x-table
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @search="search"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
      :width="450"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <FormItem label="基地" prop="farm">
          <Select v-model="form.farm" style="width: 280px">
            <Option
              v-for="item in farms"
              :value="item.id + '/' + item.name"
              :key="item.id"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="处理产品" prop="prod">
          <Select v-model="form.prod" style="width: 280px">
            <Option
              v-for="item in product"
              :value="item.id + '/' + item.name"
              :key="item.id"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="处理方式" prop="dealType">
          <Select v-model="form.dealType" style="width: 280px">
            <Option :value="1">自施</Option>
            <Option :value="2">施用</Option>
            <Option :value="3">转运</Option>
            <Option :value="4">加工</Option>
          </Select>
        </FormItem>
        <FormItem label="数量" prop="num">
          <Input v-model="form.num" style="width: 280px">
            <span slot="append">kg</span>
          </Input>
        </FormItem>
        <FormItem label="处理时间" prop="dealTime">
          <DatePicker
            type="date"
            v-model="form.dealTime"
            style="width: 280px"
          ></DatePicker>
        </FormItem>
        <FormItem label="备注" prop="remark">
          <Input
            v-model="form.remark"
            style="width: 280px"
            type="textarea"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import CU from "@/common/util";
export default {
  name: "",
  data() {
    return {
      table: {
        columns: [
          {
            title: "基地名称",
            minWidth: 100,
            key: "farmName",
          },
          {
            title: "处理产品",
            minWidth: 100,
            key: "productName",
          },
          {
            title: "处理方式",
            minWidth: 100,
            render: (h, { row }) => {
              return (
                <span>
                  {row.dealType == "1"
                    ? "自施"
                    : row.dealType == "2"
                    ? "施用"
                    : "转运"}
                </span>
              );
            },
          },
          {
            title: "数量(kg)",
            minWidth: 100,
            key: "num",
          },
          {
            title: "处理时间",
            width: 180,
            key: "dealTime",
          },
          {
            title: "备注",
            minWidth: 100,
            key: "remark",
          },
          {
            title: "操作",
            width: 150,
            render: (h, { row }) => {
              return (
                <div>
                  <a style="margin-right: 10px" onClick={() => this.edit(row)}>
                    编辑
                  </a>
                  <Poptip
                    confirm
                    transfer
                    title="确定删除吗?"
                    on-on-ok={() => this.del(row.id)}
                  >
                    <a>删除</a>
                  </Poptip>
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      farms: [],
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        farm: "",
        farmId: "",
        farmName: "",
        dealType: "",
        num: "",
        dealTime: "",
        remark: "",
        prod: "",
        productId: "",
        productName: "",
      },
      rules: {
        farm: [{ required: true, message: "请选择基地" }],
        dealType: [{ required: true, message: "请选择处理方式" }],
        num: [
          { required: true, message: "请填写数量" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "请填写正确的数量",
          },
        ],
        dealTime: [{ required: true, message: "请选择处理时间" }],
        prod: [{ required: true, message: "请选择产品" }],
      },
      product: [],
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增处理监测",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "基地",
              component: "select",
              field: "farmId",
              data: this.farms,
              defaultValue: "",
              parameterField: "id",
              showField: "name",
            },
            {
              conditionName: "处理方式",
              component: "select",
              field: "dealType",
              data: [
                { name: "自施", id: "1" },
                { name: "施用", id: "2" },
                { name: "转运", id: "3" },
              ],
              defaultValue: "",
              parameterField: "id",
              showField: "name",
            },
          ],
        },
        page: this.page,
      };
      return config;
    },
  },
  methods: {
    add() {
      this.modal = {
        show: true,
        title: "新增处理监测",
        submitLoading: false,
      };
    },

    search(data) {
      this.page.pageNo = 1;
      this.search_data = data;
      this.getList();
    },

    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getList()
    },

    getList() {
      this.table.loading = true;
      this.$post(this.$api.MANUER_DEAL_MONITOR.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    getProduct() {
      this.$post(this.$api.PRODUCT_INFO.LIST, {
        catId: "8",
      }).then((res) => {
        this.product = res.list;
      });
    },

    getBreedArea() {
      this.$post(this.$api.BASE_MANAGE.LIST).then((res) => {
        this.farms = res.list;
      });
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.farm = row.farmId + "/" + row.farmName;
      this.form.prod = row.productId + "/" + row.productName;
      this.modal = {
        show: true,
        title: "编辑处理监测",
        submitLoading: false,
      };
    },

    del(id) {
      this.$post(this.$api.MANUER_DEAL_MONITOR.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        farm: "",
        farmId: "",
        farmName: "",
        dealType: "",
        num: "",
        dealTime: "",
        remark: "",
        prod: "",
        productId: "",
        productName: "",
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.farmId = this.form.farm.split("/")[0];
        params.farmName = this.form.farm.split("/")[1];
        params.dealTime = moment(this.form.dealTime).format("YYYY-MM-DD");
        params.productId = this.form.prod.split("/")[0];
        params.productName = this.form.prod.split("/")[1];
        delete params.prod;
        this.modal.submitLoading = true;
        this.$post(
          params.id
            ? this.$api.MANUER_DEAL_MONITOR.EDIT
            : this.$api.MANUER_DEAL_MONITOR.ADD,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
  },
  mounted() {
    this.getList();
    this.getBreedArea();
    this.getProduct();
  },
};
</script>

<style lang="less" scoped>
.deal-monitor {
  width: 100%;
  height: 100%;
}
</style>